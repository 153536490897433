import { applyActionCode, sendEmailVerification, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { navigate } from 'gatsby';
import React from 'react'
import { auth } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { Signin } from '../../locust/components/Signin';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import { Signup } from '../../locust/components/Signup';
import { ToastContainer, toast } from 'react-toastify';
import Register from '../../locust/components/Register';
import CircularProgress from '@mui/material/CircularProgress';
import SpacerV from '../../locust/sections/SpacerV';
import { AuthLogo, AuthLogoLgCentered, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from '../../locust/components/Auth';
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';
import X from '../../locust/composables/X';
import { Button } from '@mui/material';
import { getCurrentUser, getUserEmail } from '../../service/user-service';


enum PageState {
    PROCESSING,
    SUCCESS,
    FAIL,
    ERROR,
    ALREADY_REGISTERED,
    CHECK_YOUR_EMAIL,
}

enum Constants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
}

const Content = {
    signup : {
        title : `It's Who You Know`,
        subtitle : 'Processing your Request...',
        fineprint  : 'By signing in, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        hasAccount : `Don't have an Account?`,
        signUpBtn : 'Sign In'
    }
}


const ProgressValue = (props : {  on ?: boolean } ) => 
    props.on ? <CircularProgress style={{ height : 40, width : 40, color : FACEBOOK_BLACK}}/>
    : <></>


const RegisterButton = ( props : {
    onClick ?: () => void,
    children ?: any 
}) => <Button
    style={{ 
        width : 350, 
        height : 55, 
        marginLeft : 10,
        marginRight : 10,
        color: 'white', 
        backgroundColor: FACEBOOK_BLACK,
        border : `2px solid ${FACEBOOK_BLACK}`

    }}
    type="submit"
    variant="contained"
    onClick={props.onClick}
>{props.children}</Button>



export default () => {

    
    const [pageState, setPageState] = React.useState(PageState.PROCESSING)
    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordVerify, setPasswordVerify] = React.useState("")
    const [activationFailedMsg, setActivationFailedMsg] = React.useState("Your activation code appears to be invalid.")                 
    
    React.useEffect(()=>{
        setTimeout(()=>{
            
            const params = new URLSearchParams(window.location.href);
            const oobCode       : any       = typeof params.get("oobCode") === "string" ? params.get("oobCode") : ""
            const windowHref    : string    = window.location.href
            const index         : number    = windowHref.indexOf("?")
            const paramUrl      : string    = windowHref.substring( index, windowHref.length )
            // const indexMode     : number    = windowHref.indexOf("mode=") + ("mode=").length
            // const indexMode2    : number    = windowHref.indexOf("&", indexMode + 1)
            // const mode          : string    = windowHref.substring(indexMode, indexMode2)
            const isRegister : boolean = windowHref.includes("verifyEmail")
            const isResetPassword : boolean = windowHref.includes("resetPassword")
            
            if(isRegister) navigate("/auth/register" + paramUrl)
            if(isResetPassword) navigate("/auth/reset-password" + paramUrl)
            //alert(`isRegister: ${isRegister}`)
            //alert(`isResetPassword: ${isResetPassword}`)
            //alert(paramUrl)
            



            


            

            //?mode=resetPassword&oobCode=ktTBfX4chQEn67m7vvE79-skLQHinE6NPJokMFD6dCQAAAGHbkq1SA&apiKey=AIzaSyAocp94aNRHw253qpWwtxF99CiZZFDcFNw&lang=en


            /*
            if(oobCode)
                applyActionCode( auth, oobCode ).then(async ( response )=>{
                    setPageState(PageState.SUCCESS)
                    const uid : string = getUIDMain()
                    const email : string = getUserEmail()
                    signOut(auth).then(()=>{
                        const pw  : string = getOnceTempSavePW(uid) as string
                        signInWithEmailAndPassword(auth, email, pw).then(()=>{
                            setTimeout(()=>{
                                navigate("/dashboard")
                            },1000)
                        })
                    })
                }).catch(async ( error )=>{
                    
                    await auth.currentUser?.getIdToken(true).then(()=>{
                        if(auth.currentUser?.emailVerified){
                            setPageState(PageState.ALREADY_REGISTERED)
                            setTimeout(()=>{
                                navigate("/dashboard")
                            },2000)
                        }
                        else{
                            const invalidCode = "auth/invalid-action-code"
                            if( invalidCode ){
                                setPageState(PageState.FAIL)
                            }
                        }
                    })
                    
            })
            else setPageState(PageState.ERROR)
            */

        },1000)

    },[])

    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                <SpacerV h={Constants.GUTTER}/>
                <AuthLogoLgCentered/>
                <SpacerV h={Constants.GUTTER}/>
                <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                <SubtitleRsp c={FACEBOOK_BLACK}>{Content.signup.subtitle}</SubtitleRsp>
                <SpacerV h={Constants.GUTTER}/>

                { pageState === PageState.PROCESSING && 
                    <>
                        <X>
                            <ProgressValue on/>
                        </X>
                    </>
                }

                { false && pageState === PageState.FAIL && 
                    <>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            {activationFailedMsg}
                        </SubtitleIIRsp>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <Button
                                style={{ 
                                    width : 350, 
                                    height : 55, 
                                    marginLeft : 10,
                                    marginRight : 10,
                                    color: 'white',
                                    backgroundColor: FACEBOOK_BLACK, 
                                    border : `2px solid ${FACEBOOK_BLACK}`

                                }}
                                type="submit"
                                
                                variant="contained"
                                onClick={async ()=>{
                                    const user  = getCurrentUser()
                                    if(user) sendEmailVerification( user ).then(()=>{
                                        setActivationFailedMsg("Check your email in a moement")

                                    }).catch(( error )=>{
                                        
                                        if(error.code === "auth/too-many-requests") navigate("/auth/attempts")
                                    })
                                }}
                            >Resend?</Button>
                        </X>     
                    </>
                }

                {pageState === PageState.ERROR && 
                    <>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            An unknown error occured. Try again later.
                        </SubtitleIIRsp>           
                    </>
                }
                
                {/* <Register/> */}
            </LayoutFS>
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}
